import { makeApiHook } from '@pentair/react-mui-5';
import gql from 'graphql-tag';
import {
  DeactivatePricingMutation,
  RequestPricingActivationMutation,
  RequestPricingActivationMutationVariables,
} from '../../../types/operations';
import { ecommerceApi } from '../../api/api';
import { userSettingsFragment } from '../../ecommerce/user-settings/api/fragments';

const activateMutation = gql`
  ${userSettingsFragment}
  mutation requestPricingActivation($customerId: String!, $locale: String) {
    requestPricingActivation(customerId: $customerId, locale: $locale) {
      ...UserSettingsFragment
    }
  }
`;

const deactivateMutation = gql`
  ${userSettingsFragment}
  mutation deactivatePricing($customerId: String!, $locale: String) {
    deactivatePricing(customerId: $customerId, locale: $locale) {
      ...UserSettingsFragment
    }
  }
`;

export enum PricingActivationAction {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export enum PricingActivationStatus {
  approved = 'Approved',
  rejected = 'Rejected',
  awaitingApproval = 'Awaiting Approval',
  awaitingCustomerID = 'Awaiting Customer ID',
  cancelled = 'Cancelled',
  inactive = '',
}

export const requestPricingActivation = async ({
  action,
  ...variables
}: RequestPricingActivationMutationVariables & { action: PricingActivationAction }) => {
  try {
    if (action === PricingActivationAction.Activate) {
      return await ecommerceApi.query<RequestPricingActivationMutation>(activateMutation, variables);
    }

    if (action === PricingActivationAction.Deactivate) {
      return await ecommerceApi.query<DeactivatePricingMutation>(deactivateMutation, variables);
    }
  } catch (err: any) {
    throw err;
  }
};

export const useRequestPricingActivation = makeApiHook(requestPricingActivation, {});
