import { Dispatch, PropsWithChildren, SetStateAction, createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { UserSettings } from '../../types/operations';
import { useEcommerceSettings } from '../ecommerce/user-settings/ecommerce-settings-provider';
import { PricingActivationStatus } from './api/request-pricing-activation';

interface PricingContext {
  customerId: string | undefined | null;
  currency: string;
  locale: string;
  pricing: UserSettings['pricing'];
  setPricing: Dispatch<SetStateAction<UserSettings['pricing']>>;
  isPricingEnabled: boolean;
  isUserAllowedToSeePrices: boolean;
  isActivationApproved: boolean;
  isActivationPending: boolean;
  isActivationRejected: boolean;
  isActivationInactive: boolean;
  isCustomerIdPending: boolean;
  activationStatus: string | undefined;
  loading: boolean;
}

export const pricingContext = createContext<PricingContext | null>(null);

const PricingProvider: React.FC<PropsWithChildren & { disabled?: boolean }> = ({ children, ...props }) => {
  const { locale } = useParams();
  const { pricingEnabled, pricing, setPricing, loading } = useEcommerceSettings();

  // Check if the pricing has been approved for the user...
  const customerId = pricing?.customerId;

  const isPricingEnabled = !props.disabled && pricingEnabled;
  const isActivationApproved =
    Boolean(customerId) && pricing?.activationRequestStatus === PricingActivationStatus.approved;
  const isCustomerIdPending = pricing?.activationRequestStatus === PricingActivationStatus.awaitingCustomerID;
  const isActivationPending =
    isCustomerIdPending || pricing?.activationRequestStatus === PricingActivationStatus.awaitingApproval;
  const isActivationRejected = pricing?.activationRequestStatus === PricingActivationStatus.rejected;
  const isActivationInactive = !isActivationRejected && !isActivationPending && !isActivationApproved;
  const isUserAllowedToSeePrices = isPricingEnabled && isActivationApproved;

  return (
    <pricingContext.Provider
      value={{
        customerId,
        currency: 'EUR',
        locale: locale || 'de',
        pricing,
        setPricing,
        isPricingEnabled,
        isUserAllowedToSeePrices,
        isActivationApproved,
        isActivationRejected,
        isActivationPending,
        isActivationInactive,
        isCustomerIdPending,
        activationStatus: pricing?.activationRequestStatus || undefined,
        loading,
      }}
    >
      {children}
    </pricingContext.Provider>
  );
};

export default PricingProvider;

/** Use this to determine if a user is permitted to see a pricing or not */
export const usePricing = () => {
  const context = useContext(pricingContext);

  if (!context) {
    throw new Error('usePricing must be used withing a PricingProvider ');
  }

  return { ...context };
};

export const usePriceFormatter = () => {
  const { isPricingEnabled, locale, currency } = usePricing();
  const { t } = useTranslation();

  return useCallback(
    (value: number | null | undefined) => {
      const isNumber = Number(value) === value;

      if (!isNumber || !isPricingEnabled) {
        return t('onRequest', { defaultValue: 'On Request' });
      }

      return Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code',
      }).format(value);
    },
    [isPricingEnabled, locale, currency, t]
  );
};
