import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from 'react';
import { UserSettings } from '../../../types/operations';
import { useAuth } from '../../auth/auth-provider';
import { useFetchEcommerceConfig } from './api/fetch-ecommerce-settings';
import { useUserEcommerceSettings } from './hooks/use-ecommerce-settings';

interface EcommerceSettingsContext {
  user: ReturnType<typeof useUserEcommerceSettings>;
  setPricing: Dispatch<SetStateAction<UserSettings['pricing']>>;
  pricingEnabled: boolean;
  pricing: UserSettings['pricing'];
  loading: boolean;
}

export const ecommerceSettingsContext = createContext<EcommerceSettingsContext | null>(null);

export const EcommerceSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [{ loading: ecommerceLoading, response: ecommerceResponse }, fetchEcommerceConfig] = useFetchEcommerceConfig();

  const pricingEnabled = ecommerceResponse?.data?.ecommerceConfiguration?.pricingConfiguration?.pricingEnabled || false;

  const user = useUserEcommerceSettings();
  const { fetchUserSettings, loading: userConfigLoading } = user;

  const loading = ecommerceLoading || userConfigLoading;

  // Store user pricing in a state, so, we can modify it at will
  const [pricing, setPricing] = useState<UserSettings['pricing']>(user?.settings?.pricing);

  useEffect(() => {
    if (isAuthenticated) {
      fetchEcommerceConfig();
      fetchUserSettings({}).then((settings) => {
        setPricing(settings.data?.userSettings?.pricing);
      });
    }
  }, [isAuthenticated, fetchUserSettings, fetchEcommerceConfig]);

  return (
    <ecommerceSettingsContext.Provider value={{ user, pricingEnabled, pricing, setPricing, loading }}>
      {children}
    </ecommerceSettingsContext.Provider>
  );
};

export default EcommerceSettingsProvider;

export const useEcommerceSettings = () => {
  const context = useContext(ecommerceSettingsContext);

  if (!context) {
    throw new Error('useEcommerceSettings must be used within a EcommerceSettingsProvider');
  }

  return context;
};
