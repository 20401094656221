import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider } from '@mui/material';
import { Typography } from '@mui/material';

import { useAuth } from './auth-provider';
import { AuthTemplate } from './auth-template';

export const AuthStateSignedIn = () => {
  const { user, signOut } = useAuth();

  return (
    <AuthTemplate title={'Welcome ' + (user?.attributes?.given_name || user?.attributes?.email)} containerSize={600}>
      <Typography variant="body1" paragraph>
        Here are some details about your personal account:
      </Typography>

      <Typography>
        <strong>Country: </strong> {user?.attributes.country}
      </Typography>

      <Typography>
        <strong>Organization: </strong> {user?.attributes.organization}
      </Typography>

      <Typography>
        <strong>Email: </strong> {user?.attributes?.email}
      </Typography>

      <Typography>
        <strong>First name: </strong> {user?.attributes?.given_name}
      </Typography>

      <Typography>
        <strong>Last name: </strong> {user?.attributes?.family_name}
      </Typography>

      <Divider sx={{ marginTop: 2, marginBottom: -1 }} />

      <Button onClick={signOut} startIcon={<FontAwesomeIcon icon={faSignOut} />}>
        Sign out
      </Button>
    </AuthTemplate>
  );
};
