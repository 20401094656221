import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Dialog } from '@mui/material';
import { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthFlow } from './auth-flow';

/**
 * @interface This specifies the user id and other user data stored in key pair in the attributes
 */
export interface AuthUser {
  id: string;
  attributes: Record<string, string>;
}

/**
 * The auth state is used to determine what to render in the AuthFlow component
 */
export enum AuthState {
  SignedIn = 'SIGNEDIN',
  SignedOut = 'SIGNEDOUT',
  PPPMigrationRequired = 'PPP_MIGRATION_REQUIRED',
  PasswordResetRequired = 'PASSWORD_RESET_REQUIRED',
  ForgotPassword = 'FORGOT_PASSWORD',
  Registering = 'REGISTERING',
  UserNotConfirmed = 'USER_NOT_CONFIRMED',
}

/**
 * for AuthContext to be used in AuthProvider
 * @prop signIn - This function is used to sign in a user
 * @prop completeNewPasswordChallenge - This function is used to complete the new password challenge
 */
interface AuthContextState {
  completeNewPasswordChallenge(newPassword: string, requiredAttributes?: Record<string, unknown>): void;
  signIn(username: string, password: string): void;
  signOut(): void;
  error: Error | null;
  loading: boolean;
  cognitoUser: CognitoUser | null;
  user: AuthUser | null;
  session: CognitoUserSession | null;
  isAuthenticated: boolean;
  awaitingSignedInUser: boolean;
  federatedSignin(provider: string): void;
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  setSigninDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  registerUser: (
    username: string,
    password: string,
    attributes: Record<string, string>
  ) => Promise<ISignUpResult | undefined>;
  confirmUserRegistration: (code: string, username?: string) => Promise<any>;
  requestNewPassword(username: string, locale: string | undefined): Promise<any>;
  updateUserAttributes: (attributes: Record<string, string>) => Promise<void>;
  resetPassword: (username: string, code: string, newPassword: string) => Promise<void>;
  resendConfirmationCode: () => Promise<void>;
}

/**
 * Initialize AuthContext with AuthContextState
 */
const AuthContext = createContext<AuthContextState | null>(null);

/**
 * @prop Config - This object must be passed Auth from @aws-amplify/auth in order to connect
 * @prop identityPoolId
 * @prop userPoolId
 * @prop userPoolWebClientId
 * @prop region
 * @prop mandatorySignIn
 *
 *@description This object must be passed Auth from @aws-amplify/auth in order to connect
 */
const config = {
  identityPoolId: process.env.REACT_APP_SuedmoIdentityPoolId,
  userPoolId: process.env.REACT_APP_SuedmoUserPoolId,
  userPoolWebClientId: process.env.REACT_APP_SuedmoUserPoolClientId,
  region: 'us-east-1',
  mandatorySignIn: false,
};

/**
 * Connect to @aws-amplify/auth
 */
Auth.configure(config);

/**
 * This will be wrapped around components in App.tsx to provide values of authContext to it's children
 * @param {React.PropsWithChildren<unknown>} children
 * @returns {AuthContextState} This will be wrapped around components in App.tsx to provide values of authContext to it's children
 */
// Pass _User and _session for test... Shouldn't affect the program
export const AuthProvider: React.FC<PropsWithChildren<{ _session?: any; _user?: any; _cognitoUser?: any }>> = ({
  children,
  _session = null,
  _user = null,
  _cognitoUser = null,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<CognitoUserSession | null>(_session);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(_cognitoUser);
  const [user, setUser] = useState<AuthUser | null>(_user);
  const [awaitingSignedInUser, setAwaitingSignedInUser] = useState(true);
  const [_username, setPrivateUsername] = useState('');
  const [signinDialogOpen, setSigninDialogOpen] = useState(false);

  /**
   * callback to sign in a user with a username and password
   * @param {string} username
   * @param {string} password
   */
  const signIn = useCallback(async (username: string, password: string) => {
    setLoading(true);
    setPrivateUsername(username);

    try {
      const cognitoUser: CognitoUser = await Auth.signIn(username, password);
      setCognitoUser(cognitoUser);
      setSigninDialogOpen(false);
    } catch (error: any) {
      setError(error);

      if (error.name === 'UserNotConfirmedException') {
        setAuthState(AuthState.UserNotConfirmed);
      }
    }
    setLoading(false);
  }, []);

  /**
   *  callback to sign out the current logged in user
   */
  const signOut = useCallback(async () => {
    setLoading(true);
    try {
      await Auth.signOut();
      localStorage.clear();
      setCognitoUser(null);
      setSession(null);
      setUser(null);
      window.location.reload();
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  }, []);

  /**
   * callback to complete the new password challange
   * @param {string} newPassword
   * @param {Object} requiredAttributes
   *
   */
  const completeNewPasswordChallenge = useCallback(
    (newPassword: string, requiredAttributes: Record<string, unknown> = {}) => {
      setLoading(true);
      try {
        cognitoUser?.completeNewPasswordChallenge(newPassword, requiredAttributes, {
          onSuccess: (result) => {
            setSession(result);
            setLoading(false);
          },
          onFailure: (error) => {
            setLoading(false);
            setError(error);
          },
        });
      } catch (error: any) {
        setError(error);
      }
    },
    [cognitoUser]
  );

  /**
   * Callback to allow federated sign in
   * @param {string} provider
   *
   */
  const federatedSignin = useCallback(async (provider: string) => {
    setLoading(true);

    //pentair-iam-sso
    try {
      await Auth.federatedSignIn({
        customProvider: provider,
      });
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  }, []);

  /**
   *  Callback to allow new user registration
   * @param {string} username
   * @param {string} password
   * @param {Object} attributes
   *
   */
  const registerUser = useCallback(async (username: string, password: string, attributes: Record<string, string>) => {
    setLoading(true);
    try {
      const response = await Auth.signUp({
        username,
        password,
        attributes,
      });

      setPrivateUsername(username);
      setAuthState(AuthState.UserNotConfirmed);

      return response;
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Callback for forgot password
   * @param {string} username
   *
   */
  const requestNewPassword = useCallback(async (username: string, locale: any) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(username, { locale });
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   *  Callback to reset password
   * @param {string} username
   * @param {string} code This is the code sent the user's email
   * @param {string} newPassword The new password user chooses
   *
   */
  const resetPassword = useCallback(async (username: string, code: string, newPassword: string) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);
  /**
   *   **** SUGGESTION: Should allow users enter their username incase of a page refresh ****
   *
   * Callback to confirm user registration
   * @param {string} code This is the code provided in the user's email
   * @param {string} username - The username is gotten from
   * _username variable stored in a state immediately after registration.
   *
   *
   */
  const confirmUserRegistration = useCallback(
    async (code: string, username = _username) => {
      setLoading(true);
      try {
        const response = await Auth.confirmSignUp(username, code);

        setAuthState(AuthState.SignedOut);

        return response;
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [_username]
  );

  /**
   *  Callback to update user attributes
   * @param {object} attributes This is the an object that contains user attributes such as country, name, et
   *
   */
  const updateUserAttributes = useCallback(
    async (attributes: Record<string, string>) => {
      setLoading(true);
      try {
        if (!cognitoUser) throw new Error('Error updating user attributes: No user found');
        if (valuesExist(attributes, user?.attributes)) {
          console.debug('Skipping update user attributes: No changes detected');
          return;
        }

        await Auth.updateUserAttributes(cognitoUser, attributes);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [cognitoUser, user?.attributes]
  );

  /**
   * Callback to resend confirmation code. It uses the _username to resend signup
   */
  const resendConfirmationCode = useCallback(async () => {
    setLoading(true);
    try {
      await Auth.resendSignUp(_username);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [_username]);

  /**
   *  Check if the user is already signed in when the component mounts
   */
  useEffect(() => {
    setLoading(true);

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setCognitoUser(cognitoUser);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
        setAwaitingSignedInUser(false);
      });
  }, [signOut]);

  /**
   * Ensure that we update the user and session when the cognitoUser changes
   */
  useEffect(() => {
    /**
     * if there is no cognitoUser, then we should clear the session and user
     * */
    if (!cognitoUser) {
      return;
    }

    /**
     * if the user is in the NEW_PASSWORD_REQUIRED state, then we should
     * set the user and clear the session
     */
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setUser((user) => {
        user = user || {
          id: cognitoUser.getUsername(),
          attributes: (cognitoUser as any)?.challengeParam?.userAttributes,
        };
        return user;
      });
    } else {
      /**
       * otherwise, we should get the session and user attributes
       */
      Auth.currentSession()
        .then((session) => {
          setSession(session);
        })
        .catch((error) => {
          setSession(null);
        });

      Auth.userAttributes(cognitoUser)
        .then((attributes) => {
          setUser((user) => {
            user = user || { id: cognitoUser.getUsername(), attributes: {} };

            user.attributes = attributes.reduce(
              (attr, next) => {
                attr[next.Name] = next.Value;
                return attr;
              },
              {} as Record<string, string>
            );

            return user;
          });

          setAwaitingSignedInUser(false);
        })
        .catch((error) => {
          console.debug(error);
        });
    }
  }, [cognitoUser, signOut]);

  const [authState, setAuthState] = useState(AuthState.SignedOut);

  useEffect(() => {
    setAuthState((currentState) => {
      if (!!user && !!session) {
        currentState = AuthState.SignedIn;

        if (user.attributes['custom:status'] === 'PPP_MIGRATION_REQUIRED') {
          currentState = AuthState.PPPMigrationRequired;
        }
      } else {
        currentState = AuthState.SignedOut;
      }

      if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        currentState = AuthState.PasswordResetRequired;
      }

      return currentState;
    });
  }, [cognitoUser, user, session]);

  useEffect(() => {
    setError(null);
  }, [authState]);

  /**
   * Value to confirm that user is logged in
   * @type {boolean}
   */
  const isAuthenticated = !!user && !!session;

  const state = useMemo(
    () => ({
      isAuthenticated,
      signIn,
      signOut,
      completeNewPasswordChallenge,
      cognitoUser,
      error,
      loading,
      awaitingSignedInUser,
      session,
      user,
      federatedSignin,
      authState,
      setAuthState,
      registerUser,
      resetPassword,
      confirmUserRegistration,
      setSigninDialogOpen,
      requestNewPassword,
      updateUserAttributes,
      resendConfirmationCode,
    }),
    [
      isAuthenticated,
      signIn,
      signOut,
      completeNewPasswordChallenge,
      cognitoUser,
      error,
      loading,
      awaitingSignedInUser,
      session,
      user,
      federatedSignin,
      authState,
      setAuthState,
      registerUser,
      resetPassword,
      confirmUserRegistration,
      setSigninDialogOpen,
      requestNewPassword,
      updateUserAttributes,
      resendConfirmationCode,
    ]
  );

  return (
    <AuthContext.Provider value={state}>
      {children}
      <Dialog open={signinDialogOpen} maxWidth={false} onClose={() => setSigninDialogOpen(false)}>
        <AuthFlow />
      </Dialog>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) throw new Error('useAuth must be used within an AuthProvider');

  return auth;
};

const valuesExist = (a: Record<string, any> | undefined, b: Record<string, any> | undefined) => {
  if (!a || !b) return false;

  return Object.entries(a).every(([key, value]) => {
    return b[key] === value;
  });
};
