import { CssBaseline, ThemeProvider } from '@mui/material';
import { Suspense, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnalyticsEvents, useAnalytics } from './feature/analytics/analytics-provider';
import { Loading } from './feature/app/loading';
import { AuthProvider } from './feature/auth/auth-provider';
import { EcommerceSettingsProvider } from './feature/ecommerce/user-settings/ecommerce-settings-provider';
import PricingProvider from './feature/pricing/pricing-provider';
import { SupportProvider } from './feature/support/support-provider';
import TopBarOptionsProvider from './feature/topbar-menu-options/topbar-menu-options-provider';

import ThemeDemoPage from './theme/demopage/demopage';
import { pentairTheme } from './theme/theme';

const PageIndex = lazy(() => import('./pages'));
const PageNotFound = lazy(() => import('./pages/404'));
const PageUnauthorizedFound = lazy(() => import('./pages/unathorized'));
const PageValveConfigurator = lazy(() => import('./pages/valve-configurator'));
const PageValveDetailsNew = lazy(() => import('./pages/valve-details-new'));
const PageValveSelector = lazy(() => import('./pages/valve-selector'));
const QuotationConfirmation = lazy(() => import('./pages/quotation-confirmation'));
const PageUserAccountNew = lazy(() => import('./pages/user-account-new'));

const Account = lazy(() => import('./pages/account'));
const Quotations = lazy(() => import('./pages/quotations'));

const AppLayout = lazy(() => import('./feature/page-layout/page-layout'));
const ShoppingCartProvider = lazy(() => import('./feature/ecommerce/shopping-cart/hook/useShoppingcart'));
const SuedmoAssistantProvider = lazy(() => import('./feature/valve-selector/suedmo-assistant-provider'));

function App() {
  return (
    <ThemeProvider theme={pentairTheme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/:locale/" element={<LocaleProvider />}>
            <Route element={<AppProviders />}>
              <Route element={<AppLayout />}>
                <Route index element={<PageIndex />} />
                <Route path="valve-selector" element={<PageValveSelector />} />
                <Route path="valve" element={<PageValveConfigurator />} />
                <Route path="valve/:id" element={<PageValveDetailsNew />} />
                <Route path="theme-demo-page" element={<ThemeDemoPage />} />
                <Route path="account" element={<PageUserAccountNew />}>
                  <Route index element={<Account />} />
                  <Route path="quotations" element={<Quotations />} />
                </Route>
                <Route path="quotations/:quotationId/quotation-submitted" element={<QuotationConfirmation />} />
                <Route path="unauthorized" element={<PageUnauthorizedFound />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<LocaleProvider />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;

export const LocaleProvider = () => {
  const { locale } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (locale !== i18n.language && locale?.length === 2) {
      // Change the language
      i18n.changeLanguage(locale);
    }
    // Only run this effect when the  language in the url changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (locale !== i18n.language) {
      let path;
      if (!(location.pathname + '/').match('^/[a-z]{2}/')) {
        // Redirect to the same path but with the new language
        path = '/' + i18n.language + location.pathname;
      } else {
        path = window.location.pathname.replace(`/${locale}`, `/${i18n.language}`);
      }
      navigate(path);
    }
    // Only run this effect when the I18N language changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return <Outlet />;
};

export const AppProviders = () => {
  const { track } = useAnalytics();

  return (
    <SupportProvider>
      <AuthProvider>
        <SuedmoAssistantProvider>
          <EcommerceSettingsProvider>
            <PricingProvider>
              <ShoppingCartProvider
                onOpen={() => track(AnalyticsEvents.VIEW_CART)}
                onEmptyCart={() => track(AnalyticsEvents.CART_CLEAR)}
                onItemAdded={(item) => track(AnalyticsEvents.CART_ADD, { items: [item.id] })}
                onItemRemoved={(item) => track(AnalyticsEvents.CART_REMOVE, { items: [item.id] })}
                onItemUpdated={(item) => track(AnalyticsEvents.CART_UPDATE, { items: [item.id] })}
              >
                <TopBarOptionsProvider>
                  <Outlet />
                </TopBarOptionsProvider>
              </ShoppingCartProvider>
            </PricingProvider>
          </EcommerceSettingsProvider>
        </SuedmoAssistantProvider>
      </AuthProvider>
    </SupportProvider>
  );
};
