import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { useAuth } from '../../auth/auth-provider';
import SelectCountry from '../../form/fields/select-country';
import { useQuotationForm } from './hooks/use-quotation-form';
// import { QuotationComplete } from './quotation-complete';

export const QuotationForm: React.FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { record, change, validationErrors, loading, isCompleted, submitForm, error, hasValidationErrors } =
    useQuotationForm();

  // This should return null as it awaits useEffect to  run and redirect to quotation page
  if (isCompleted && !error) {
    return null;
  }

  return (
    <form onSubmit={submitForm} noValidate>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <PentairTextField
            id="projectName"
            required
            label={t('ecommerce:projectName.label', { defaultValue: 'Project name' })}
            description={t('ecommerce:projectName.description', { defaultValue: '' })}
            placeholder={t('ecommerce:projectName.placeholder', {
              defaultValue: 'Name of your project',
            })}
            value={record.projectName || ''}
            onChange={(e) => change('projectName', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['projectName']}
            helperText={validationErrors['projectName']}
            disabled={loading}
          />

          <PentairTextField
            id="projectDescription"
            required
            multiline
            rows={4}
            label={t('ecommerce:projectDescription.label', { defaultValue: 'Project description' })}
            description={t('ecommerce:projectDescription.description', { defaultValue: '' })}
            placeholder={t('ecommerce:projectDescription.placeholder', {
              defaultValue: 'Describe your project',
            })}
            value={record.projectDescription || ''}
            onChange={(e) => change('projectDescription', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['projectDescription']}
            helperText={validationErrors['projectDescription']}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="firstName"
            required
            label={t('ecommerce:firstName.label', { defaultValue: 'First name' })}
            description={t('ecommerce:firstName.description', { defaultValue: '' })}
            placeholder={t('ecommerce:firstName.placeholder', {
              defaultValue: 'Enter your first name',
            })}
            value={record.shippingInfo?.firstName || ''}
            onChange={(e) => change('shippingInfo.firstName', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.firstName']}
            helperText={validationErrors['shippingInfo.firstName']}
            disabled={loading || Boolean(user)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="lastName"
            required
            label={t('ecommerce:lastName.label', { defaultValue: 'Last name' })}
            description={t('ecommerce:lastName.description', { defaultValue: '' })}
            placeholder={t('ecommerce:lastName.placeholder', {
              defaultValue: 'Enter your last name',
            })}
            value={record.shippingInfo?.lastName || ''}
            onChange={(e) => change('shippingInfo.lastName', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.lastName']}
            helperText={validationErrors['shippingInfo.lastName']}
            disabled={loading || Boolean(user)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="email"
            required
            label={t('ecommerce:email.label', { defaultValue: 'Email' })}
            description={t('ecommerce:email.description', { defaultValue: '' })}
            placeholder={t('ecommerce:email.placeholder', {
              defaultValue: 'Your email address',
            })}
            value={record.shippingInfo?.email || ''}
            onChange={(e) => change('shippingInfo.email', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.email']}
            helperText={validationErrors['shippingInfo.email']}
            disabled={loading || Boolean(user)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="phoneNumber"
            required
            label={t('ecommerce:phone1.label', { defaultValue: 'Phone' })}
            description={t('ecommerce:phone1.description', { defaultValue: '' })}
            placeholder={t('ecommerce:phone1.placeholder', {
              defaultValue: 'Enter a phone number',
            })}
            value={record.shippingInfo?.phone1 || ''}
            onChange={(e) => change('shippingInfo.phone1', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.phone1']}
            helperText={validationErrors['shippingInfo.phone1']}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="organisationName"
            required
            label={t('ecommerce:organisationName.label', { defaultValue: 'Organisation' })}
            description={t('ecommerce:organisationName.description', { defaultValue: '' })}
            placeholder={t('ecommerce:organisationName.placeholder', {
              defaultValue: 'Name of your organisation',
            })}
            value={record.shippingInfo?.organisationName || ''}
            onChange={(e) => change('shippingInfo.organisationName', e.target.value)}
            margin="dense"
            error={!!validationErrors['shippingInfo.organisationName']}
            helperText={validationErrors['shippingInfo.organisationName']}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="addressLine1"
            required
            label={t('ecommerce:addressLine1.label', { defaultValue: 'Street' })}
            description={t('ecommerce:addressLine1.description', { defaultValue: '' })}
            placeholder={t('ecommerce:addressLine1.placeholder', {
              defaultValue: 'Enter street name and number',
            })}
            value={record.shippingInfo?.addressLine1 || ''}
            onChange={(e) => change('shippingInfo.addressLine1', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.addressLine1']}
            helperText={validationErrors['shippingInfo.addressLine1']}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="postal"
            required
            label={t('ecommerce:postal.label', { defaultValue: 'Postal' })}
            description={t('ecommerce:postal.description', { defaultValue: '' })}
            placeholder={t('ecommerce:postal.placeholder', { defaultValue: 'Enter postal' })}
            value={record.shippingInfo?.postal || ''}
            onChange={(e) => change('shippingInfo.postal', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.postal']}
            helperText={validationErrors['shippingInfo.postal']}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PentairTextField
            id="city"
            required
            label={t('ecommerce:city.label', { defaultValue: 'City' })}
            description={t('ecommerce:city.description', { defaultValue: '' })}
            placeholder={t('ecommerce:city.placeholder', { defaultValue: 'Enter city' })}
            value={record.shippingInfo?.city || ''}
            onChange={(e) => change('shippingInfo.city', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.city']}
            helperText={validationErrors['shippingInfo.city']}
            disabled={loading}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <PentairTextField
            id="state"
            label={t('ecommerce:state.label', { defaultValue: 'State' })}
            description={t('ecommerce:state.description', { defaultValue: '' })}
            placeholder={t('ecommerce:state.placeholder', { defaultValue: 'Enter state' })}
            value={record.shippingInfo?.state || ''}
            onChange={(e) => change('shippingInfo.state', e.target.value)}
            variant="standard"
            margin="dense"
            error={!!validationErrors['shippingInfo.state']}
            helperText={validationErrors['shippingInfo.state']}
            disabled={loading}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <SelectCountry
            id="country"
            aria-label="select country"
            required
            label={t('ecommerce:country.label', { defaultValue: 'Country' })}
            description={t('ecommerce:country.description', { defaultValue: '' })}
            // placeholder={t('ecommerce:country.placeholder', {
            //   defaultValue: 'Enter country',
            // })}
            value={record.shippingInfo?.country || ''}
            onChange={(e, val) => change('shippingInfo.country', val?.l)}
            margin="dense"
            error={!!validationErrors['shippingInfo.country']}
            helperText={validationErrors['shippingInfo.country']}
            disabled={loading}
          />
        </Grid>
      </Grid>

      {!user && (
        <>
          <FormControlLabel
            sx={{ marginTop: 1 }}
            control={
              <Checkbox
                value={record.createAccount || false}
                onChange={() => {
                  change('createAccount', !record.createAccount);
                }}
              />
            }
            label="Create an account?"
            disabled={loading}
          />

          {record.createAccount && (
            <PentairTextField
              type="password"
              label={'Password'}
              description={'shopping_cart.quotation.shipping_info.password_description'}
              variant="standard"
              placeholder="Enter password"
              margin={'dense'}
              disabled={loading}
              value={record.userPassword || ''}
              onChange={(e) => change('userPassword', e.target.value)}
              error={!!validationErrors['userPassword']}
              helperText={validationErrors['userPassword']}
            />
          )}
        </>
      )}

      <InputLegalAgreement
        value={record.termsAccepted || false}
        onChange={() => change('termsAccepted', !record.termsAccepted)}
        error={!!validationErrors['termsAccepted']}
        helperText={validationErrors['termsAccepted'] || ''}
      />

      <InputNewsAndUpdates
        value={record.subscribeToNewsAndUpdates || false}
        onChange={() => change('subscribeToNewsAndUpdates', !record.subscribeToNewsAndUpdates)}
      />
      {error && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          {error.message}
        </Alert>
      )}
      <Box display="flex" justifyContent={'flex-end'}>
        <Button
          type="submit"
          variant="contained"
          // fullWidth
          startIcon={loading ? <CircularProgress size={'sm'} /> : <FontAwesomeIcon icon={faCheck} />}
          disabled={loading || Boolean(error) || hasValidationErrors}
        >
          {t('ecommerce:buttonLabel.requestQuotation', { defaultValue: 'Request quotation' })}
        </Button>
      </Box>
    </form>
  );
};
interface InputLegalAgreementProps {
  value: boolean | undefined | null;
  onChange: (value: boolean) => void;
  error?: boolean;
  helperText?: React.ReactNode;
}
export const InputLegalAgreement: React.FC<InputLegalAgreementProps> = ({ value, onChange, error, helperText }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: '#eeeeee', padding: 1, marginTop: 1.5, width: '100%' }}>
      <FormControl error={value !== undefined && !value}>
        <FormControlLabel
          onChange={() => onChange(!value)}
          sx={{ verticalAlign: 'top', alignItems: 'flex-start' }}
          control={<Checkbox sx={{ paddingY: 0 }} />}
          label={
            <Trans i18nKey="iam:terms.label">
              I have read and agree to the{' '}
              <Link
                target="_blank"
                href="https://www.pentair.com/assets/su-tc-terms-and-conditions-of-service-10614-en"
              >
                terms of service
              </Link>{' '}
              and the{' '}
              <Link target="_blank" href="https://www.pentair.com/en-us/legal/privacy-notice.html">
                privacy statement
              </Link>
              .
            </Trans>
          }
        />
        <FormHelperText>
          {error &&
            t('errors:not.termsAccepted', {
              defaultValue: 'Please accept the terms in order to continue',
            })}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

interface InputNewsAndUpdatesProps {
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  error?: boolean;
  helperText?: React.ReactNode;
}
export const InputNewsAndUpdates: React.FC<InputNewsAndUpdatesProps> = ({ value, onChange, error, helperText }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ backgroundColor: '#eeeeee', padding: 1, marginTop: 1, marginBottom: 2, width: '100%' }}>
      <FormControl error={value !== undefined && !value}>
        <FormControlLabel
          onChange={() => onChange(!value)}
          sx={{ verticalAlign: 'top', alignItems: 'flex-start' }}
          control={<Checkbox sx={{ paddingY: 0 }} />}
          label={t('ecommerce:newsAndUpdates.label', {
            defaultValue: 'I want to subscribe to news and updates.',
          })}
        />
      </FormControl>
    </Box>
  );
};
